import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from './Components/Form/Form';
import Login from './Components/Login/Login';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import './App.css';
import { Guidelines } from './Components/Guidelines/Guidelines';

class App extends React.PureComponent {

    render() {
        // console.log('[App.js] render');
        return (
            <Router>
                <Switch>
                    <Route
                        path="/login"
                        exact
                        render={() => <Login />}
                    />
                    <ProtectedRoute
                        authed={this.props.main.loggedInStatus}
                        path="/form"
                        component={Form}
                    />
                    <ProtectedRoute
                        authed={this.props.main.loggedInStatus}
                        path="/guidelines"
                        component={Guidelines}
                    />
                    <Redirect to="/login" />
                </Switch>

            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        main: {
            loggedInStatus: state.main.authenticated,
            baseURL: state.main.baseURL
        }
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
