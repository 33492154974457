import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import storeConfig from './StoreConfig';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { setLoginStatus } from './actions/actionUtils';
import './index.css';

const appStore = storeConfig.init();

const token = localStorage.getItem('token');
if (token) {
    const decodedToken = jwt.decode(token, { complete: true });
    const now = moment().unix();
    const tokenExpiration = decodedToken.payload.exp;
    if (now <= tokenExpiration) {
        appStore.dispatch(setLoginStatus(true));
    } else {
        appStore.dispatch(setLoginStatus(false));
    }

}

ReactDOM.render(
    <Provider store={appStore}>
        <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
