import React, { Component, Fragment } from 'react';
// eslint-disable-next-line
import { Document, Page, pdfjs } from "react-pdf";
import { Card, Layout, PageHeader } from 'antd';
import PDF from '../../AOI-Guideline.pdf';
import logo from '../../logo_color.png';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const { Content } = Layout;

export class Guidelines extends Component {



    _goBack = (e, history) => {
        history.push('form');
    };

    removeTextLayerOffset = () => {
        const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
        textLayers.forEach(layer => {
            const { style } = layer;
            style.top = "0";
            style.left = "0";
            style.transform = "";
        });
    }

    render() {
        const { history } = this.props;

        return (
            <Fragment>
                <Layout>
                    <Content style={{ height: '100vh', overflow: 'auto' }}>
                        <Card style={{ margin: '0.5rem' }}>
                            <PageHeader
                                title='Guidelines'
                                subTitle="Click the arrow to go back an place a new order"
                                extra={<img
                                    alt="example"
                                    src={logo}
                                    width="100px"
                                />}
                                onBack={(e) => this._goBack(e, history)}
                            >
                                <Document
                                    file={PDF}
                                >
                                    <Page
                                        size="A4"
                                        scale={0.94}
                                        pageNumber={1}
                                        onLoadSuccess={this.removeTextLayerOffset}

                                    />
                                </Document>
                            </PageHeader>
                        </Card>
                    </Content>
                </Layout>
            </Fragment>

        );
    }
}