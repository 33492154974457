import {
	SET_LOGIN
} from '../actions/actionTypes';

const defaultState = {
	lat: 47,
	long: 13,
	zoomLevel: 4,
	authenticated: false,
	// baseURL: 'http://localhost:3000',
	// baseURL: 'http://192.168.200.163:3000',
	baseURL: 'https://tasking.euspaceimaging.com',
};

export function initializationReducer(state = defaultState, action) {
	switch (action.type) {
		case SET_LOGIN:
			return { ...state, authenticated: action.value };
		default:
			return state;
	}
}
