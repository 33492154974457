import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { setLoginStatus } from '../../actions/actionUtils';
import { withRouter } from 'react-router-dom';
import classes from './Login.module.css';
import Background from '../../logo_background.jpg';
import Logo from '../../logo_color.png';

class Login extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			loading: false
		}
	}

	componentDidUpdate = () => {
		const { history } = this.props;
		const isAuthed = this.props.main.loggedInStatus;
		if (isAuthed) {
			history.push('/form');
		};
	};

	enterIconLoading = () => {
		this.setState({ loading: true });
	};

	exitIconLoading = () => {
		this.setState({ loading: false });
	};

	handleClose = () => {
		this.setState({ visible: false, loading: false });
	};

	handleSubmit = (e, history) => {
		// console.log(e);

		this.enterIconLoading();
		const username = e.username;
		const password = e.password;
		const credentials = {
			"username": username,
			"password": password
		}
		const URL_LOGIN = `${this.props.main.baseURL}/api/v1/login`;
		fetch(URL_LOGIN, {
			method: "POST",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(credentials),
			credentials: "include"
		})
			.then(data => {
				if (data.status === 401) {
					this.setState({ visible: true });
					throw new Error('Invalid credentials;');
				}
				return data.json()
			})
			.then(json => {
				const token = json.token;
				if (token) {
					localStorage.setItem('token', token);
					this.exitIconLoading();
					this.props._setLoginStatus(true);
					history.push('/form');
				} else {
					history.push('/login');
				};
			})
			.catch(err => {
				console.error(err);
				this.exitIconLoading();
				history.push('/login');
			});
	};

	render() {

		const { history } = this.props;
		var sectionStyle = {
			margin: "0",
			padding: "0",
			width: "100vw",
			height: "100vh",
			backgroundImage: `url(${Background})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			display: "table-cell",
			verticalAlign: 'middle',
			position: 'absolute',
			overflowY: 'auto'
		};
		return (
			<section style={sectionStyle}>
				<Form
					name="normal_login"
					className={classes.login_form}
					initialValues={{
						remember: true,
					}}
					onFinish={(e) => this.handleSubmit(e, history)}
				>
					<img alt="example"
						src={Logo}
						className={classes.Form_Image}
						style={{ marginBottom: '2.5rem' }}
					/>
					<Form.Item
						name="username"
						className={classes.Form_Item}
						rules={[
							{
								required: true,
								message: 'Please input your Username!',
							},
						]}
					>
						<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
					</Form.Item>
					<Form.Item
						name="password"
						className={classes.Form_Item}
						rules={[
							{
								required: true,
								message: 'Please input your Password!',
							},
						]}
					>
						<Input
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="Password"
						/>
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className={classes.login_form_button}
							loading={this.state.loading}
						>
							Log in
        				</Button>
					</Form.Item>
					{this.state.visible ? (
						<Alert
							message="Invalid credentials. Please try again!"
							type="error"
							showIcon={true}
							closable
							afterClose={this.handleClose}
						/>
					) : null}
				</Form>
			</section>
		);
	};
};

function mapStateToProps(state) {
	return {
		main: {
			loggedInStatus: state.main.authenticated,
			baseURL: state.main.baseURL
		}
	};
};

function mapDispatchToProps(dispatch) {
	return {
		_setLoginStatus: (status) => dispatch(setLoginStatus(status)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
