import React, { Fragment } from 'react'
import { loadModules } from 'esri-loader'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classes from './Map.module.css'


class Map extends React.Component {
    constructor(props) {
        super(props)
        this.mapRef = React.createRef()
    }

    async componentDidMount() {
        // console.log('DID MOUNT')
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        const [ArcGISMap,
            MapView,
            ScaleBar,
            Sketch,
            GraphicsLayer,
            geometryEngine,
            webMercatorUtils] = await loadModules(['esri/Map', 'esri/views/MapView',
                'esri/widgets/ScaleBar', 'esri/widgets/Sketch', 'esri/layers/GraphicsLayer', 'esri/geometry/geometryEngine',
                'esri/geometry/support/webMercatorUtils'], { css: true });

        const layer = new GraphicsLayer();
        const map = new ArcGISMap({
            basemap: "topo",
        })

        this.view = new MapView({
            container: this.mapRef.current,
            map: map,
            center: [13, 47],
            zoom: 4
        });

        const scaleBar = new ScaleBar({
            view: this.view,
            style: 'ruler',
            unit: 'metric'
        });

        const sketch = new Sketch({
            layer: layer,
            view: this.view,
            // graphic will be selected as soon as it is created
            creationMode: "create",
            availableCreateTools: ["point"]
        });

        this.view.ui.add(sketch, "top-right");

        this.view.ui.add(scaleBar, "bottom-left");

        sketch.on('create', async evt => {
            // evt.stopPropagation();
            this.removeAllLayers(this.view.map);
            this.props.removeKML();
            const buffer = await geometryEngine.geodesicBuffer(evt.graphic.geometry, 5.643, "kilometers");

            console.log(buffer);
            const wgs84Rings = buffer.rings[0].map(coord => {
                return webMercatorUtils.xyToLngLat(coord[0], coord[1]);
            });
            const aoi = {
                geometry: {
                    coordinates: [wgs84Rings]
                }
            };
            // const area = await geometryEngine.geodesicArea(buffer, 'square-kilometers');
            // console.log(area);
            this.props.bufferAOI(aoi);
        })

        if (this.props.aoi !== null && this.view !== null && this.view !== undefined) {
            const layer = this.getLayer(this.props.aoi);
            this.addLayerToMap(this.view, this.view.map, layer, this.props.aoi.geometry.coordinates);
        }

    }

    componentWillUnmount() {
        // console.log("Will Unmount")

    }

    addLayerToMap = (mapView, map, layer, position) => {
        map.add(layer);
        mapView.goTo(position);
    };

    removeAllLayers = (map) => {
        // map.removeAll();
        const existingLayer = this.view.map.allLayers.find((layer) => layer.title === 'Uploaded AOI');
        // console.log(existingLayer);
        if (existingLayer !== null) {
            this.view.map.remove(existingLayer);
        }

    };

    getLayer = async (aoi) => {
        const [FeatureLayer, Polygon] = await loadModules(["esri/layers/FeatureLayer", "esri/geometry/Polygon"], { css: true })
        return new FeatureLayer({
            fields: [{
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
            },
            {
                name: "Name",
                alias: "Name",
                type: "string"
            }],
            title: 'Uploaded AOI',
            objectIdField: "ObjectID",
            geometryType: "polygon",
            spatialReference: { wkid: 4326 },
            source: [{
                geometry: new Polygon({
                    rings: aoi.geometry.coordinates
                }),
                attributes: {
                    ObjectID: 1,
                    Name: 'Order AOI'
                }
            }],
            renderer: {
                type: "simple",
                symbol: {
                    type: "simple-fill", // autocasts as SimpleLineSymbol()
                    color: [25, 111, 116, 0.5],
                    outline: {
                        width: 2,
                        color: [25, 111, 116]
                    }
                }
            },
            popupEnabled: true,
            popupTemplate: {
                title: "Uploaded Data",
                content: [
                    {
                        type: "fields",
                        fieldInfos: [
                            {
                                fieldName: "Name",
                                label: "Name"
                            }
                        ]
                    }
                ]
            }
        });
    };

    render() {
        if (this.view !== null && this.view !== undefined) {
            this.removeAllLayers(this.view.map);
        }
        if (this.props.aoi !== null && this.view !== null && this.view !== undefined) {
            const layer = this.getLayer(this.props.aoi);
            this.addLayerToMap(this.view, this.view.map, layer, this.props.aoi.geometry.coordinates);
        }
        return (
            <Fragment>
                <div className={classes.webmap} ref={this.mapRef} />
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        main: {
            lat: state.main.lat,
            long: state.main.long,
            zoom: state.main.zoomLevel,
            baseURL: state.main.baseURL
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Map)
)
