/*
  this module creates a store that gets
  passed to all the components in main.js
*/

import { createStore, applyMiddleware  } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducer from './reducers/appReducer';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import { batchedSubscribe } from 'redux-batched-subscribe';

const createStoreWithMiddleware = applyMiddleware(
  thunk,
  multi,
)(createStore);

// Ensure our listeners are only called once, even when one of the above
// middleware call the underlying store's `dispatch` multiple times
const createStoreWithBatching = batchedSubscribe(
  fn => fn()
)(createStoreWithMiddleware)

export default {
  init() {
    return createStoreWithBatching(appReducer, composeWithDevTools());
  }
};