import moment from "moment";

const toOrderJSON = (json) => {
	return {
		orderReference: json.orderReference,
		collectionStart: moment(json.collectionStart).format('DD-MM-YYYY'),
		collectionEnd: moment(json.collectionEnd).format('DD-MM-YYYY'),
		aoi: json.aoi,
		area: json.area,
		resolution: json.resolution,
		ona: json.ona,
		cloudCover: json.cloudCover,
		bands: json.bands,
		bitDepth: json.bitDepth,
		resamplingKernel: json.resamplingKernel,
		licenseType: json.licenseType,
		uploadedFileName: json.uploadedFileName
	}
}

export {
	toOrderJSON,
};
