import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Card, Layout, PageHeader, Select, Input, DatePicker, Upload, message, Modal, Divider } from 'antd';
import { SendOutlined, InboxOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import * as uniqid from 'uniqid';
import { uploadKML, sendOrder, getReference } from '../../utils/run'
import { toOrderJSON } from '../../utils/helpers';
import Map from '../Map/Map'
import classes from './Form.module.css';
import logo from '../../logo_color.png';

const { Content } = Layout;
const { Option } = Select;

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            sendOrderDisabled: true,
            sendOrderBtnLoading: false,
            modalVisible: false,
            modalOrderReference: '',
            modalContent: <p>Order is processing. Please wait...</p>,
            modalGoBackBtnDisabled: true,
            file: null,
            loading: false,
            orderReference: '',
            collectionStart: moment(moment.now()),
            collectionEnd: moment(moment.now()),
            aoi: null,
            area: 0,
            resolution: '30cm',
            ona: '0-40',
            cloudCover: '<15',
            bands: '4 Bands Pansharpened',
            bitDepth: '16 bits',
            resamplingKernel: 'Enhanced',
            licenseType: 'Internal Use',
            uploadedFileName: ''
        }
    }

    async componentDidMount() {
        // const today = moment().format('YYYY-MM-DD');
        const noSpaceToday = moment().format('YYYYMMDD')
        const countResponse = await getReference(this.props.main.baseURL);
        if (countResponse.hasOwnProperty('status') && countResponse.status === 200) {
            this.setState({ orderReference: `MSD ${noSpaceToday}_${countResponse.count}`, modalOrderReference: `MSD ${noSpaceToday}_${countResponse.count}` });
        } else if (countResponse.hasOwnProperty('status') && countResponse.status === 401) {
            this.props.history.push('/login');
        } else {
            this.setState({ orderReference: ``, modalOrderReference: '' });
        };
    }

    onChangeState = (type, value) => {
        switch (type) {
            case 'orderReference':
                this.setState({ orderReference: value.target.value, modalOrderReference: value.target.value });
                break;
            case 'collectionStart':
                this.setState({ collectionStart: value });
                break;
            case 'collectionEnd':
                this.setState({ collectionEnd: value });
                break;
            case 'resolution':
                this.setState({ resolution: value });
                break;
            case 'ona':
                this.setState({ ona: value });
                break;
            case 'cloudCover':
                this.setState({ cloudCover: value });
                break;
            case 'bands':
                if (value === '4 Bands Pansharpened') {
                    this.setState({ bands: value, resamplingKernel: 'Enhanced' });
                } else {
                    this.setState({ bands: value, resamplingKernel: '4x4 Cubic Convolution' });
                };
                break;
            case 'bitDepth':
                this.setState({ bitDepth: value });
                break;
            case 'resamplingKernel':
                this.setState({ resamplingKernel: value });
                break;
            case 'licenseType':
                this.setState({ licenseType: value });
                break;
            default:
                const updatedOrder = { ...this.state };
                this.setState({ updatedOrder });
        }
    };

    uploadKMLFile = async (file, fileList) => {
        this.setState({ file: null, uploading: true, fileList: [] });
        const feasibilityUploadResponse = await uploadKML(this.props.main.baseURL, file.file);
        if (feasibilityUploadResponse.hasOwnProperty('status') && feasibilityUploadResponse.status === 200) {
            file.onSuccess(this.setState({ file: file.file, uploading: false, aoi: feasibilityUploadResponse.data, area: feasibilityUploadResponse.area, uploadedFileName: feasibilityUploadResponse.fileName }));
        } else if (feasibilityUploadResponse.hasOwnProperty('status') && feasibilityUploadResponse.status === 401) {
            this.props.history.push('/login');
        } else if (feasibilityUploadResponse.hasOwnProperty('status') && feasibilityUploadResponse.status === 400 && feasibilityUploadResponse.message === 'The AOI size is smaller than 100 sq. km. Please upload again.') {
            message.error('The AOI size is smaller than 100 sq. km. Please upload again.');
            file.onError(this.setState({ file: null, uploading: false, aoi: null }));
        } else {
            file.onError(this.setState({ file: null, uploading: false, aoi: null }));
        };
    };

    bufferAOI = async (aoi) => {
        const data = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: aoi.geometry.coordinates
            },
            properties: {
                name: 'Buffer AOI'
            }
        };
        const now = `${Date.now()}`;
        const id = uniqid().toString();
        this.setState({ file: 'Buffer AOI', uploading: false, aoi: data, area: 100, uploadedFileName: `${now}_${id}_bufferAOI.kml` })
    }

    removeKML = () => {
        this.setState({ file: null, uploading: false, aoi: null, area: 0, uploadedFileName: '', fileList: [] });
    };

    sendOrder = async () => {
        // console.log(this.state);
        const order = toOrderJSON(this.state);
        // console.log(order);
        this.setState({
            sendOrderBtnLoading: true,
            modalVisible: true
        });

        const sendOrderResponse = await sendOrder(this.props.main.baseURL, order);
        if (sendOrderResponse.hasOwnProperty('status') && sendOrderResponse.status === 200) {
            this.setState({
                sendOrderDisabled: true,
                sendOrderBtnLoading: false,
                file: null,
                loading: false,
                orderReference: '',
                collectionStart: moment(moment.now()),
                collectionEnd: moment(moment.now()),
                aoi: null,
                area: 0,
                resolution: '30cm',
                ona: '0-40',
                cloudCover: '<15',
                bands: '4 Bands Pansharpened',
                bitDepth: '16 bits',
                resamplingKernel: '',
                licenseType: 'Internal Use',
                uploadedFileName: '',
                modalGoBackBtnDisabled: false,
                modalContent: <Fragment>
                    <p>We acknowledge reception of your order reference: <b>{this.state.modalOrderReference}</b>.</p>
                    <p>The order will be active without delay in our tasking system. If this is a mistake please immediately contact your point of contact to cancel the tasking.</p>
                </Fragment>
            });
        } else if (sendOrderResponse.hasOwnProperty('status') && sendOrderResponse.status === 401) {
            this.props.history.push('/login');
        } else {
            message.error('There was an error. Please try again!');
        }
    };

    closeModal = (history) => {
        history.go('/form');
    }

    _onUploadChange = (file) => {
        // console.log(file);
        const status = file.file.status;
        if (status === 'removed') {
            this.setState({ fileList: [] })
        } else {
            this.setState({ fileList: [file.file] });
        };
    }


    render() {
        const { history } = this.props;
        let sendBtnDisabled = true;

        if (this.state.aoi === null ||
            this.state.area === 0 ||
            this.state.file === null ||
            this.state.orderReference === '' ||
            this.state.resamplingKernel === '' ||
            this.state.uploadedFileName === '') {
            sendBtnDisabled = true;
        } else {
            sendBtnDisabled = false;
        }

        return (
            <Fragment>
                <Layout>
                    <Content style={{ height: '100vh', overflow: 'auto' }}>
                        <Card style={{ margin: '0.5rem' }}>
                            <Modal
                                title="Thank you !"
                                visible={this.state.modalVisible}
                                closable={false}
                                width={700}
                                footer={[
                                    <Button
                                        key="back"
                                        onClick={() => this.closeModal(history)}
                                        disabled={this.state.modalGoBackBtnDisabled}
                                    >
                                        Close
                                    </Button>
                                ]}
                            >
                                {this.state.modalContent}
                            </Modal>
                            <PageHeader
                                title='Place a new order with European Space Imaging'
                                extra={<img
                                    alt="example"
                                    src={logo}
                                    width="100px"
                                />}
                            >
                                <Row>
                                    <Button
                                        type="dashed"
                                        danger
                                        onClick={() => history.push('/guidelines')}
                                    >
                                        Guidelines
                                    </Button>
                                    <Divider orientation="left">Order Reference</Divider>
                                    <Col style={{ width: '25rem' }}>
                                        <p>Add Order Reference</p>
                                        <Input
                                            disabled={true}
                                            placeholder="order reference"
                                            value={this.state.orderReference}
                                            onChange={(e) => this.onChangeState('orderReference', e)}
                                        />
                                    </Col>
                                    <Divider orientation="left">AOI</Divider>
                                    <Row gutter={[24, 16]} style={{ width: '100%' }}>
                                        <Col span={6} style={{ marginTop: '1rem' }}>
                                            <Row>
                                                <Col>
                                                    <p>Upload KML file</p>
                                                    <Upload.Dragger
                                                        accept=".kml"
                                                        name="files"
                                                        customRequest={this.uploadKMLFile}
                                                        multiple={false}
                                                        onRemove={this.removeKML}
                                                        onChange={this._onUploadChange}
                                                        fileList={this.state.fileList}
                                                    >
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                        <p className="ant-upload-hint">The AOI file is a KML file.</p>
                                                    </Upload.Dragger>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={18} style={{ marginTop: '1rem' }}>
                                            <Map
                                                aoi={this.state.aoi}
                                                bufferAOI={this.bufferAOI}
                                                removeKML={this.removeKML}
                                            />
                                        </Col>
                                    </Row>
                                    <Divider orientation="left" style={{ marginTop: '2rem' }}>Collection Period</Divider>
                                    <Col span={5} style={{ marginTop: '1rem' }}>
                                        <Col style={{ width: '25rem' }}>
                                            <p>Collection Start</p>
                                            <DatePicker

                                                format="DD-MM-YYYY"
                                                defaultValue={this.state.collectionStart}
                                                onChange={(e) => this.onChangeState('collectionStart', e)}
                                            />
                                        </Col>

                                    </Col>
                                    <Col span={5} style={{ marginTop: '1rem' }}>
                                        <Col style={{ width: '25rem' }}>
                                            <p>Collection End</p>
                                            <DatePicker

                                                format="DD-MM-YYYY"
                                                defaultValue={this.state.collectionEnd}
                                                onChange={(e) => this.onChangeState('collectionEnd', e)}
                                            />
                                        </Col>
                                    </Col>
                                    <Divider orientation="left">Product Parameters</Divider>
                                    <Col span={12} style={{ marginTop: '1rem' }}>
                                        <Col style={{ width: '25rem', marginBottom: '1rem' }}>
                                            <p>Resolution</p>
                                            <Select
                                                defaultValue={this.state.resolution}
                                                onChange={(e) => this.onChangeState('resolution', e)}
                                                style={{ width: '380px' }}
                                            >
                                                <Option value="30cm">30cm</Option>
                                                <Option value="40cm">40cm</Option>
                                                <Option value="50cm">50cm</Option>
                                            </Select>
                                        </Col>

                                        <Col style={{ width: '25rem', marginBottom: '1rem' }}>
                                            <p>Off Nadir Angle</p>
                                            <p className={classes.notes}><b><i>Please note that any restriction on the off-Nadir Angle may have an impact on the collection timeline.</i></b></p>
                                            <Select
                                                defaultValue={this.state.ona}
                                                onChange={(e) => this.onChangeState('ona', e)}
                                                style={{ width: '380px' }}
                                            >
                                                <Option value="0-40">0-40</Option>
                                                <Option value="0-35">0-35</Option>
                                                <Option value="0-30">0-30</Option>
                                                <Option value="0-25">0-25</Option>
                                                <Option value="0-20">0-20</Option>
                                            </Select>
                                        </Col>

                                        <Col style={{ width: '25rem', marginBottom: '1rem' }}>
                                            <p>Cloud Cover Protection</p>
                                            <p className={classes.notes}><b><i>Please note that any restriction on the cloud cover protection may have an impact on the collection timeline.</i></b></p>
                                            <Select
                                                defaultValue={this.state.cloudCover}
                                                onChange={(e) => this.onChangeState('cloudCover', e)}
                                                style={{ width: '380px' }}
                                            >
                                                <Option value="<15">less 15 %</Option>
                                                <Option value="<10">less 10 %</Option>
                                            </Select>
                                        </Col>

                                        <Col style={{ width: '25rem', marginBottom: '1rem' }}>
                                            <p>Product Options/Bands</p>
                                            <Select
                                                defaultValue={this.state.bands}
                                                onChange={(e) => this.onChangeState('bands', e)}
                                                style={{ width: '380px' }}
                                            >
                                                <Option value="4 Bands Pansharpened">4 Bands Pansharpened</Option>
                                                <Option value="4 Bands Bundle">4 Bands Bundle</Option>
                                            </Select>
                                        </Col>
                                    </Col>
                                    <Col span={12} style={{ marginTop: '1rem' }}>
                                        <Col style={{ width: '25rem', marginBottom: '1rem' }}>
                                            <p>Bit Depth</p>
                                            <Select
                                                disabled={true}
                                                defaultValue={this.state.bitDepth}
                                                onChange={(e) => this.onChangeState('bitDepth', e)}
                                                style={{ width: '380px' }}
                                            >
                                                <Option value="16 bits">16 bits</Option>
                                            </Select>
                                        </Col>

                                        <Col style={{ width: '25rem', marginBottom: '1rem' }}>
                                            <p>Resampling Kernel</p>
                                            <Select
                                                disabled={true}
                                                value={this.state.resamplingKernel}
                                                onChange={(e) => this.onChangeState('resamplingKernel', e)}
                                                style={{ width: '380px' }}
                                            >
                                                <Option value="Enhanced">Enhanced</Option>
                                                <Option value="4x4 Cubic Convolution">4x4 Cubic Convolution</Option>
                                            </Select>
                                        </Col>

                                        <Col style={{ width: '25rem', marginBottom: '1rem' }}>
                                            <p>License Type</p>
                                            <Select
                                                disabled={true}
                                                defaultValue={this.state.licenseType}
                                                onChange={(e) => this.onChangeState('licenseType', e)}
                                                style={{ width: '380px' }}
                                            >
                                                <Option value="Internal Use">Internal Use</Option>
                                            </Select>
                                        </Col>
                                    </Col>


                                </Row>
                                <Divider orientation="left">Place Order</Divider>
                                <Row justify="start">
                                    <Col>
                                        <Button
                                            type="primary"
                                            icon={<SendOutlined />}
                                            loading={this.state.sendOrderBtnLoading}
                                            disabled={sendBtnDisabled}
                                            onClick={this.sendOrder}
                                        >
                                            Place order
                                    </Button>
                                    </Col>
                                </Row>
                            </PageHeader>
                        </Card>
                    </Content>
                </Layout>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        main: {
            loggedInStatus: state.main.authenticated,
            baseURL: state.main.baseURL,
        },
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
