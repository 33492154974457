const uploadKML = async (baseURL, file) => {
    const token = localStorage.getItem('token');
    let formData = new FormData();
    formData.append('gis', file);
    const aoi = await fetch(`${baseURL}/api/v1/upload/gis`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: formData
    });
    return aoi.json();
};

const sendOrder = async (baseURL, json) => {
    const token = localStorage.getItem('token');
    const order = await fetch(`${baseURL}/api/v1/order`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(json)
    });
    return order.json();
};

const getReference = async (baseURL) => {
    const token = localStorage.getItem('token');
    const order = await fetch(`${baseURL}/api/v1/reference`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });
    return order.json();
}

export {
    sendOrder, uploadKML, getReference
};