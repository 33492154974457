import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Idle from 'react-idle';

function PrivateRoute({ component: Component, authed, ...rest }) {
    // return (
    //   <Route
    //     {...rest}
    //     render={(props) => 
    //         authed === true
    //       ? <Component {...props} />
    //       : <Redirect to={{pathname: '/login', state: {from: props.location}}} 
    //         />}
    //   />
    // )
    const baseURL = rest.main.baseURL;
    return (
        <Idle
            {...rest}
            timeout={1000 * 60 * 60}
            baseURL={baseURL}
            render={({ idle }) => {
                return (
                    <Route
                        {...rest}
                        render={(props) => {
                            return (
                                authed === true
                                    ? idle === true
                                        ? logOutAndRedirect()
                                        : <Component {...props} />
                                    : <Redirect to={{ pathname: '/login', state: { from: props.location } }}
                                    />

                            )
                        }}
                    />
                )
            }}
        />
    )
}

function mapStateToProps(state) {
    return {
        main: {
            baseURL: state.main.baseURL
        }
    };
}


const logOutAndRedirect = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
};

export default connect(mapStateToProps)(PrivateRoute);
